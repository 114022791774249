import { combineReducers } from "redux";
import userInfo from "../components/captureUserInfo/reducer";
import floors from "../components/floorSwitcher/reducer";
import points from "../components/searchBar/reducer";
import building from "../components/buildingSelecter/reducer";

/**
 * all available reducers are wrapped by the combine reducers function
 */

const rootReducer = combineReducers({
  floors,
  points,
  userInfo,
  building
});

export default rootReducer;
