/**
 * All redux actions are wrapped inside the ActionCreators object
 */

import * as UserInfo from "../components/captureUserInfo/action";
import * as Floor from "../components/floorSwitcher/action";
import * as Points from "../components/searchBar/action";
import * as Building from "../components/buildingSelecter/action";

export const ActionCreators = Object.assign({}, Floor, Points, UserInfo, Building);
