import axios from "axios";
// import * as Utilities from '../helpers/utilities';

/*global SERVICE_URL */
/*eslint no-undef: "error"*/

const axiosInstance = axios.create({
  baseURL: SERVICE_URL,
  timeout: 2000,
  withCredentials: true,
});


/**
 * request interceptor definition.
 * adds the custom request header Auth token if available.
 */
axiosInstance.interceptors.request.use(
  function (config) {
    // const token = Utilities.readURLData(window.location.href).organisation;
    // if (token)
    //   config.headers['BECO-USERTOKEN'] = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /*global Promise */
    /*eslint no-undef: "error"*/
    return Promise.reject(error.response);
  }
);
export { axiosInstance };