import * as Types from '../../constants/actionTypes';

/**
 * reducer which holds the current selected floor.
 */

const initialState = {
  current_floor: null,
  floor_list: [],
  floors_list: [],
  activeFloorButton: '',
  navigationInProgress: false
};

export default function floors(state = initialState, action) {
  switch (action.type) {
    case Types.FLOOR_SELECT_SUCCESS:
      return { ...state, current_floor: action.payload };
    case Types.SAVE_FLOOR_LIST_SUCCESS:
      return { ...state, floor_list: action.payload };
      case Types.SAVE_FLOORS_LIST_SUCCESS:
      return { ...state, floors_list: action.payload };
    case Types.SAVE_ACTIVE_FLOOR:
      return { ...state, activeFloorButton: action.payload };
    case Types.SET_NAVIGATION_STATE:
      return { ...state, navigationInProgress: action.payload };
    default:
      return state;
  }
}