import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import MapDataProvider from "../../services/mapDataProvider";
import "./style.scss";
import * as Utilities from '../../helpers/utilities';

class BuildingSelecter extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.animationRef = null;

    this.state = {
      selectedBuilding: null,
      building: [],
      initialFloor: [],
      showLeftArrow: false,
      showRightArrow: false,
    };
    this.mapDataProvider = MapDataProvider.getInstance();
    let url = window.location.href;
    this.data = Utilities.readURLData(url);
  }

  componentDidMount() {
    if (this.data && this.data.floor) {
      let getFloor = this.data.floor;
      let initialFloor = this.props?.floors?.floors_list.filter(floorItem => floorItem?.name === parseInt(getFloor));
      this.setState({
        initialFloor,
      });

    }
    setTimeout(() => {
      let building = this.mapDataProvider.getMapObject().getBuildings();
      this.props.actions.saveBuildingList(building);
      this.setState({
        building,
      }
        , () => {
         
          let getCurrentBuilding = localStorage.getItem('cbul') !== null ? parseInt(localStorage.getItem('cbul'), 10) : this.state?.building[0]?.id;
          if (!this.state.building.some(building => building.id === getCurrentBuilding)) {
            getCurrentBuilding = this.state?.building[0]?.id;
          }
          this.props.actions.setCurrentBuilding(getCurrentBuilding);
          this.setState({
            selectedBuilding: this.state?.building.find((building) => building.id === getCurrentBuilding)
          });
          localStorage.setItem('cbul', getCurrentBuilding);
        });
      let getBuildingInfo = this.state.building.find(building => building.id === this.state.selectedBuilding.id);
      let floors;

      if (getBuildingInfo) {
        floors = this.mapDataProvider
          .getMapObject()
          .getFloors()
          .sort(function (a, b) {
            return a.name < b.name ? 1 : -1;
          }).filter(floor => getBuildingInfo.floorIds.includes(floor.id));
        this.props.actions.setCurrentFloor(floors[floors.length - 1].id);
        this.mapDataProvider.getMapObject().switchFloor(floors[floors.length - 1].id);

      } else {
        floors = this.mapDataProvider
          .getMapObject()
          .getFloors()
          .sort(function (a, b) {
            return a.name < b.name ? 1 : -1;
          });

        this.props.actions.setCurrentFloor(this.mapDataProvider.getInitialFloor());
        this.mapDataProvider.getMapObject().switchFloor(this.mapDataProvider.getInitialFloor());
      }
      this.props.actions.saveFloorList(floors);
      this.mapDataProvider.getMapObject().setCenter();
    }, 250);

    setTimeout(() => {
      this.updateArrowsVisibility();
    }, 300);

  }



  handleClick = (event, building) => {
    this.setState({ selectedBuilding: building });
    this.props.actions.setCurrentBuilding(building.id);
    localStorage.setItem('cbul', building.id);
    let getBuildingInfo = this.state.building.find(item => item.id === building.id);
    let floors;

    if (getBuildingInfo) {
      floors = this.mapDataProvider
        .getMapObject()
        .getFloors()
        .sort(function (a, b) {
          return a.name < b.name ? 1 : -1;
        }).filter(floor => getBuildingInfo.floorIds.includes(floor.id));
      this.props.actions.setCurrentFloor(floors[floors.length - 1].id);
      this.mapDataProvider.getMapObject().switchFloor(floors[floors.length - 1].id);
    } else {
      floors = this.mapDataProvider
        .getMapObject()
        .getFloors()
        .sort(function (a, b) {
          return a.name < b.name ? 1 : -1;
        });
      this.props.actions.setCurrentFloor(this.mapDataProvider.getInitialFloor());
      this.mapDataProvider.getMapObject().switchFloor(this.mapDataProvider.getInitialFloor());
    }
    this.props.actions.saveFloorList(floors);
    this.mapDataProvider.getMapObject().setCenter();
  };

  handleScroll = (direction) => {
    const container = this.containerRef.current;
    const scrollAmount = 250; // Adjust the scroll amount as needed
    const targetScrollPosition = direction === 'left' ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;

    this.animateScroll(container.scrollLeft, targetScrollPosition);
  };

  animateScroll = (start, target) => {
    const container = this.containerRef.current;
    const duration = 300; // Adjust the duration as needed
    let startTime;

    const animate = (currentTime) => {
      if (!startTime) {
        startTime = currentTime;
      }

      const progress = (currentTime - startTime) / duration;
      const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

      container.scrollLeft = start + (target - start) * easeInOutQuad(progress);

      if (progress < 1) {
        this.animationRef = requestAnimationFrame(animate);
      }
    };

    cancelAnimationFrame(this.animationRef);
    this.animationRef = requestAnimationFrame(animate);
  };

  updateArrowsVisibility = () => {
    const container = this.containerRef.current;
    if (container) {
      this.setState({
        showLeftArrow: container.scrollLeft > 0,
        showRightArrow: container.scrollLeft + container.clientWidth < container.scrollWidth,
      });
    }
  };

  componentWillUnmount() {
    cancelAnimationFrame(this.animationRef);
  }





  render() {
    const { building, selectedBuilding } = this.state;
    if (!this.props.floors.navigationInProgress)
      if (building.length > 1 && selectedBuilding !== null)
        return (
          <div className="build-swiper-wrapper">
            <div className="build-swiper" ref={this.containerRef} onScroll={this.updateArrowsVisibility}>
              {building.map((building) => (
                <div
                  className={`pill ${selectedBuilding && selectedBuilding.id === building.id ? 'active' : ''}`}
                  key={building.id}
                  onClick={(event) => this.handleClick(event, building)}
                >
                  <span className="icon-building"></span>
                  {building.name}
                </div>
              ))}
            </div>

            {this.state.showLeftArrow && <button className="arrowbtn left" style={{ pointerEvents: 'all' }} onClick={() => this.handleScroll('left')}><span className="icon-chevron-left"></span></button>}
            {this.state.showRightArrow && <button className="arrowbtn right" style={{ pointerEvents: 'all' }} onClick={() => this.handleScroll('right')}><span className="icon-chevron-right"></span></button>}
          </div>
        );
    return null;
  }
}

const mapStateToProps = state => {
  return {
    building: state.building,
    floors: state.floors

  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingSelecter);

BuildingSelecter.propTypes = {
  actions: PropTypes.object,
  building: PropTypes.object,
  floors: PropTypes.object

};