import MapDataProvider from './mapDataProvider';
import markers from './markers';
const mapDataProvider = MapDataProvider.getInstance();

/**
 * Map initialization is handled here.
 * Returns a promise once map initialization is completed, also stores the active map object to
 * @MapDataProvider object and can be accessed from anywhere within the application with
 * [MapDataProvider.getMapDataObject()] 
 */
const initializeMap = (apiKey, site, initialFloor,zoom) => {
  /*global Promise */
  /*eslint no-undef: "error"*/

  return new Promise(function (resolve, reject) {

    var params = {
      siteId: site,
      color: "#c1d1a3",
      minZoom: 2,
      maxZoom: 5,
      floorIndex:initialFloor ? initialFloor : 0,
      defaultZoom: zoom,
      routeType: 'default', //options -- > 'dashed' or 'default' apply default if not specified
      routeColor: '#3498db', // color of route if default selected
      markersList: ["*"], //show all available markers 
      kioskMode: false, //if true, point labels will be disabled 
      markerObj: markers
    };

    let target = 'beco-container';
    if (apiKey && site) {
      try {
        /*global initMap */
        /*eslint no-undef: "error"*/
        initMap(apiKey, target, params, function (map, initialFloorId) {
          mapDataProvider.setMapObject(map);
          mapDataProvider.setInitialFloor(initialFloorId);
          // map.switchFloor(initialFloorId);
          const points = mapDataProvider.getMapObject().getPoints();
          if (site === "apollo-chennai-main-hospital") {
            const matchingItem = points.find(item => item.name === "Counter 5C");
            if (matchingItem) {
              map.moveToPointWithZoom(matchingItem.pointId,zoom);
            }
          }
          resolve(map);
        }, function (exception) {
          reject(exception);
        });
      } catch (error) {
        reject(error);
      }
    } else {
      reject();
    }
  });
};

export default initializeMap;