import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import initializeMap from '../services/mapServices';
import * as Utilities from '../helpers/utilities';
import MapView from './../components/mapView';

import Loader from '../components/loader';

import Home from '../modules/home/container';
import NotFound from '../modules/notFound/container';

/**
 * Application main router component
 * 
 * contains all the available routes and components in the application 
 * The storelist component and idle time calculator component are rendered directly within the main router
 * component and these component will listen to the active events within the application
 */

export default class Router extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      map_object_read: false,
      isMobile: window.innerWidth < 768,
      zoom: this.calculateZoom(window.innerWidth),
    };
    let url = window.location.href;
    if (url.includes('src') || url.includes('dest')) {
      url = Utilities.removeParam('dest', Utilities.removeParam('src', url));
    }
    this.data = Utilities.readURLData(url);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this.data && this.data.organisation && this.data.site) {
      const headers = {
        'Content-Type': 'application/json',
        'BECO-USERTOKEN': this.data.organisation 
      };
  
      fetch('https://app.becomap.com/api/v1/get-sites/', {
        method: 'GET',
        headers: headers,
      })
      .then(response => response.json())
      .then(data => {
        const getSiteData = data.find(item => item.identifier === this.data.site);
        const mapBgColor = getSiteData ? getSiteData.bg_color : null;
        
        initializeMap(this.data.organisation, this.data.site, this.data.floor, this.state.zoom, mapBgColor)
          .then(() => {
            this.setState({
              map_object_read: true
            });
          })
          .catch(() => {
            window.location.href = '/error';
          });
      })
      .catch(error => console.error('Error fetching campus data:', error));
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isMobile: window.innerWidth < 768,
      zoom: this.calculateZoom(window.innerWidth), // Update zoom based on new window width
    });
    
  }
  calculateZoom(windowWidth) {
    // Your logic to determine the zoom level based on window width
    return windowWidth < 768 ? 3 : 3;
  }
  
  render() {
    if (this.state.map_object_read)
      return (
        <BrowserRouter>
          <MapView />
          <Switch>
            <Route exact path='/' component={Home} />
          </Switch>
        </BrowserRouter>
      );
    else
      return (
        <BrowserRouter>
          <MapView />
          <Loader />
          <Route exact path='/error' component={NotFound} />
        </BrowserRouter>
      );
  }
}
