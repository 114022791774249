import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import * as Utilities from "../../helpers/utilities";
import CaptureUserInfo from "../captureUserInfo";
import "./style.scss";
/**
 * Map component displayed in the application.
 * This component is rendered only once in the application and is available throughout the
 * app. User interactions on this component will update the corresponding reducer.
 */

class MapView extends React.Component {
  constructor(props) {
    super(props);
    let url = window.location.href;
    if (url.includes("src") || url.includes("dest")) {
      url = Utilities.removeParam("dest", Utilities.removeParam("src", url));
    }
    this.data = Utilities.readURLData(url);
    if (!this.data) this.props.history.replace("/error");
  }

  get showUserInfoModal() {
    let userInfo = localStorage["userInfo"]
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    const site_identifier = this.data ? this.data.site : null;
    const organisation = this.data ? this.data.organisation : null;

    if (site_identifier !== null)
      if (userInfo === null || userInfo === undefined) {
        return (
          <CaptureUserInfo
            site_identifier={site_identifier}
            token={organisation}
          />
        );
      }
    return null;
  }

  render() {
    return (
      <>
        <div id="cd-beco-map">
          <div id="beco-container" className="map"></div>
        </div>
        {/* {this.showUserInfoModal} */}
      </>
    );
  }
}

MapView.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  })
};

export default withRouter(MapView);
