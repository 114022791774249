import React from 'react';
import './style.scss';
import SearchBar from '../searchBar';
import NavigationCard from '../navigationCard';
import BuildingSelecter from '../buildingSelecter';


export default class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
    }


    render() {
        return (
            <div className="beco-cards">
                <div className='card-header-wrapper'>
                    <SearchBar />
                    <BuildingSelecter/>
                </div>
                <NavigationCard />
            </div>
        );
    }
}
