import PropTypes from "prop-types";
import React from "react";
import * as Bootstrap from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../actions/index";
import Cards from "../../../components/cards";
import Footer from "../../../components/footer";
import Loader from "../../../components/loader";
import * as Encryptor from "../../../helpers/encryption";
import * as Utilities from "../../../helpers/utilities";
import "../style/index.scss";
import MapDataProvider from "./../../../services/mapDataProvider";

// const sendCustomEvent = (category, action, label) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//   });
// };

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.mapDataProvider = MapDataProvider.getInstance();
  }

  componentDidMount() {
    this.collectData();
    this.addOnPointClickFocusListner();
  }

  // Listener to Focus Icon on Click
  addOnPointClickFocusListner = () => {
    this.mapDataProvider.getMapObject().setOnPointClickListener(this._onFocus);
  };

  _onFocus = id => {
    // moveToPoint show the Focused Icon in center
    const { points } = this.props;
    if (points !== null && points.current_route_data === null && points.preview_start === false)
      if (points.points_list) {
        let _item = points.points_list.filter(val => val.pointId === id);
        if (_item !== null || _item !== undefined) {
          let item = { ..._item[_item.length - 1] };
          this.props.actions.setCurrentFloor(item.locationId);
          // this.mapDataProvider.getMapObject().setCenter();
          this.props.actions.setDestinationPoint(item);
          this.props.actions.setDestinationPointText(item.name);
          this.props.actions.setDestinationSearchError(false);
          // sendCustomEvent('Points', 'Points Clicked', `${item.name}`);
          // this.mapDataProvider.getMapObject().moveToPoint(id);
        }
      }
  };

  async collectData() {
    let floors = await this.mapDataProvider
      .getMapObject()
      .getFloors()
      .sort(function (a, b) {
        return a.name < b.name ? 1 : -1;
      });
    this.props.actions.saveFloorList(floors);
    this.props.actions.saveFloorsList(floors);
    let points = await this.mapDataProvider.getMapObject().getPoints();
    this.props.actions.savePointsList(points);
      // this.mapDataProvider.getMapObject().moveToPoint(9690);
          // map.
      // this.mapDataProvider.getMapObject().moveToPointWithZoom(9690,5);
    if (
      window.location.search.includes("org") ||
      window.location.search.includes("site") 
    ) {
      let org = Utilities.findURLParam("org", window.location.href);
      let site = Utilities.findURLParam("site", window.location.href);
      let floor = Utilities.findURLParam("floor", window.location.href);

      let enc = Encryptor.encrypt(org + "&" + site + "&" + floor);
      this.props.history.replace({
        pathname: "/",
        search: enc
      });
    }
     
    this.data = Utilities.fetchSourceAndDestination(window.location.href);
    if (this.data) {
      const source_point = this.props.points.points_list.find(
        pt => pt.uuid == this.data.source
      );

      const destination_point = this.props.points.points_list.find(
        pt => pt.uuid == this.data.destination
      );


      if (source_point) {
        //set source point and search text
        this.props.actions.setSourcePoint(source_point);
        this.props.actions.setSourcePointText(source_point.name);
        this.props.actions.toggleMultiSelection(true);
        this.props.actions.setDestinationInputActive(true);
        //clear loading screen and display map
        this.removeSourceAndDestParams();
        this.clearLoading();
      } else if (source_point && destination_point) {
        //set source point and search text
        this.props.actions.setSourcePoint(source_point);
        this.props.actions.setSourcePointText(source_point.name);
        this.props.actions.toggleMultiSelection(true);
        //set destination point and search text
        this.props.actions.setCurrentFloor(destination_point.locationId);
        this.mapDataProvider
          .getMapObject()
          .switchFloor(destination_point.locationId);
        this.mapDataProvider.getMapObject().setCenter();
        this.props.actions.setDestinationPoint(destination_point);
        this.props.actions.setDestinationPointText(destination_point.name);
        //clear loading screen and display map
        this.removeSourceAndDestParams();
        this.clearLoading();
      } else {
        //clear url search params, clear loading screen and display map
        this.removeSourceAndDestParams();
        this.clearLoading();
      }
    } else {
      //clear loading screen and display map
      this.clearLoading();
    }
  }

  removeSourceAndDestParams() {
    let removed_search;
    let search = this.props.location.search;
    if (search.includes("dest") || search.includes("src")) {
      removed_search = Utilities.removeParam(
        "dest",
        Utilities.removeParam("src", search)
      );
      this.props.history.replace({
        pathname: "/",
        search: removed_search
      });
    }
  }

  clearLoading() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <Bootstrap.Container fluid className="outer-wrapper">
        {this.state.loading ? (
          <Loader />
        ) : (
            <>
              <Cards />
              <Footer />
            </>
          )}
      </Bootstrap.Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    points: state.points,
    floors: state.floors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

Home.propTypes = {
  actions: PropTypes.object,
  points: PropTypes.object,
  floors: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};
