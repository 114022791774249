/**
 * All map related data are stored in a MapDataProvider object.
 * Data manipulation is handled with getters and setters
 */

export default class MapDataProvider {

  static mapDataProviderInstance = null;
  static _mapObject = null;
  static _isMapInitialized = false;
  static _initialFloor;
  _navigationRoute = null;


  /**
   * returns an active instance of the class if it already has an instance defined, 
   * or else will return a new instance of the class. 
   * @returns {MapDataProvider}
   */
  static getInstance() {
    if (MapDataProvider.mapDataProviderInstance == null) {
      MapDataProvider.mapDataProviderInstance = new MapDataProvider();
    }
    return this.mapDataProviderInstance;
  }

  setMapObject(mapObject) {
    this._mapObject = mapObject;
  }

  getMapObject() {
    return this._mapObject;
  }

  getIsMapInitialized() {
    return this._isMapInitialized;
  }

  setIsMapInitialized(isMapInitialized) {
    this._isMapInitialized = isMapInitialized;
  }

  setInitialFloor(initialFloorId) {
    this._initialFloor = initialFloorId;
  }

  getInitialFloor() {
    return this._initialFloor;
  }

  setNavigationRoute(route) {
    this._navigationRoute = route;
  }

  getNavigationRoute() {
    return this._navigationRoute;
  }

}