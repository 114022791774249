import * as Types from '../../constants/actionTypes';

/**
 * reducer which holds the current selected floor.
 */

const initialState = {
  current_building: null,
  building_list: [],
};

export default function building(state = initialState, action) {
  switch (action.type) {
    case Types.BUILDING_SELECT_SUCCESS:
      return { ...state, current_building: action.payload };
    case Types.SAVE_BUILDING_LIST_SUCCESS:
      return { ...state, building_list: action.payload };
    default:
      return state;
  }
}