import * as Types from '../../constants/actionTypes';

/**
 * redux action to update the current selected floor
 */

export function setSourcePointText(source_name) {
  return (dispatch) => {
    dispatch({ type: Types.SET_SOURCE_POINT_SEARCH });
    return dispatch({
      type: Types.SET_SOURCE_POINT_SEARCH_SUCCESS,
      payload: source_name
    });
  };
}

export function setDestinationPointText(destination_name) {
  return (dispatch) => {
    dispatch({ type: Types.SET_DESTINATION_POINT_SEARCH });
    return dispatch({
      type: Types.SET_DESTINATION_POINT_SEARCH_SUCCESS,
      payload: destination_name
    });
  };
}


export function setSourcePoint(source) {
  return (dispatch) => {
    dispatch({ type: Types.SET_SOURCE_POINT });
    return dispatch({
      type: Types.SET_SOURCE_POINT_SUCCESS,
      payload: source
    });
  };
}

export function setDestinationPoint(destination) {
  return (dispatch) => {
    dispatch({ type: Types.SET_DESTINATION_POINT });
    return dispatch({
      type: Types.SET_DESTINATION_POINT_SUCCESS,
      payload: destination
    });
  };
}

export function setDestinationInputActive(isActive) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_DESTINATION_POINT_INPUT_ACTIVE,
      payload: isActive
    });
  };
}

export function savePointsList(points) {
  return (dispatch) => {
    return dispatch({
      type: Types.SAVE_POINTS_LIST_SUCCESS,
      payload: points
    });
  };
}

export function toggleMultiSelection(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_MULTI_SELECT,
      payload: value
    });
  };
}

export function previewRouteStart(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.PREVIEW_START,
      payload: value
    });
  };
}

export function setSourceSuggestionVisibility(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_SRC_SUGGESTION_VIEW,
      payload: value
    });
  };
}

export function setDestinationSuggestionVisibility(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_DEST_SUGGESTION_VIEW,
      payload: value
    });
  };
}

export function setSourceSearchError(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_SOURCE_SEARCH_ERROR,
      payload: value
    });
  };
}

export function setDestinationSearchError(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_DESTINATION_SEARCH_ERROR,
      payload: value
    });
  };
}

export function setCurrentRoute(route) {
  return (dispatch) => {
    dispatch({ type: Types.SET_CURRENT_ROUTE_START });
    return dispatch({
      type: Types.SET_CURRENT_ROUTE,
      payload: route
    });
  };
}