import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import MapDataProvider from "../../services/mapDataProvider";
import "./style.scss";

class FloorSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      // selectedFloor: null,
      floors: []
    };
    this.mapDataProvider = MapDataProvider.getInstance();
  }

  componentDidMount() {
    let floors = this.mapDataProvider
        .getMapObject()
        .getFloors()
        .sort(function (a, b) {
          return a.name < b.name ? 1 : -1;
        });

    this.props.actions.saveFloorsList(floors);
  }

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  };

  handleClick = (event, floor) => {
    this.setState({
      selectedFloor: event.target.innerText,
      listOpen: false
    });
    this.props.actions.setCurrentFloor(floor.id);
    this.mapDataProvider.getMapObject().switchFloor(floor.id);
    this.mapDataProvider.getMapObject().setCenter();
    this.props.actions.toggleMultiSelection(false);
  };

  get renderFloorList() {
    return this.props.floors.floor_list.map(floor => {
      return (
        <li
          key={floor.id}
          className="floor-list-item"
          onClick={event => this.handleClick(event, floor)}
        >
          {floor.label}
        </li>
      );
    });
  }

  getFloorInfo = id => {
    let floor = this.props.floors.floors_list.find(floor => id == floor.id);
    return floor;
  };

  render() {
    const { listOpen } = this.state;
    const { floors } = this.props;
    if (!floors.navigationInProgress)
      if (floors.floor_list.length > 1)
        return (
          <div className="beco-switcher">
            <div className="floor-dropdown">
              <button
                type="button"
                className={"selected-title btn" + (listOpen ? " show" : "")}
                onClick={this.toggleList}
              >
                {this.getFloorInfo(this.props.floors.current_floor) != undefined
                  ? this.getFloorInfo(this.props.floors.current_floor).label
                  : ""}
              </button>
              {listOpen && (
                <ul className="floor-list">{this.renderFloorList}</ul>
              )}
            </div>
          </div>
        );
    return null;
  }
}

const mapStateToProps = state => {
  return {
    floors: state.floors,
    building: state.building
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorSwitcher);

FloorSwitcher.propTypes = {
  actions: PropTypes.object,
  floors: PropTypes.object
};
