import * as Encryptor from './encryption';

export function readURLData(location) {
  let organisation;
  let site;
  let floor;
  const url = new URL(location);
  if (url.search != '') {
    if (location.includes('org') && location.includes('site')) {
      organisation = url.searchParams.get("org");
      site = url.searchParams.get("site");
      floor = url.searchParams.get("floor");
      if (organisation && site)
        return { organisation, site , floor };
      else
        return null;
    }
    else {
      let search = Encryptor.decrypt(url.search.substring(1));
      let full_url = url.origin + '/?' + search;
      let decrypted_url = new URL(full_url);
      organisation = decrypted_url.searchParams.get("org");
      site = decrypted_url.searchParams.get("site");
      floor = decrypted_url.searchParams.get("floor");
      if (organisation && site)
        return { organisation, site, floor };
      else
        return null;
    }
  } else {
    return null;
  }
}

export function fetchSourceAndDestination(location) {
  let source;
  let destination;
  const url = new URL(location);
  source = url.searchParams.get("src");
  destination = url.searchParams.get("dest");
  if (source) {
    return { source};
  }
  else if (source && destination) {
    return { source, destination };
  }
  else
    return null;
}

export function removeParam(key, location) {
  let rtn = location.split("?")[0],
    param,
    params_arr = [],
    queryString = (location.indexOf("?") !== -1) ? location.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function findURLParam(key, location) {
  let param,
    params_arr = [],
    queryString = (location.indexOf("?") !== -1) ? location.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        return params_arr[i];
      }
    }
  }
}


