export const getImage = (image) => {
  let name = "default";
  switch (image) {
    case "BE_Straigt_Range":
      name = 'straigt';
      break;
    case "BE_Floor_Switcher":
      name = 'floor_switcher';
      break;
    case "BE_Destination_Reached":
      name = 'destination_reached';
      break;
    case "BE_Slight_Turn_left":
      name = 'slight_left';
      break;
    case "BE_Slight_Turn_right":
      name = 'slight_right';
      break;
    case "BE_Turn_left":
      name = 'turn_left';
      break;
    case "BE_Turn_right":
      name = 'turn_right';
      break;
    case "BE_Sharp_Turn_left":
      name = 'sharp_left';
      break;
    case "BE_Sharp_Turn_right":
      name = 'sharp_right';
      break;
    case "BE_U_Turn_left":
      name = 'sharp_left';
      break;
    case "BE_U_Turn_right":
      name = 'sharp_right';
      break;
  }
  return name;
};