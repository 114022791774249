import * as Types from "../../constants/actionTypes";
import * as URL from "../../constants/urls";
import { axiosInstance } from "../../services/axiosInstance";

/**
 * redux action to update the current selected floor
 */

export const postUserInfo = (data, token) => {
  const AUTH_TOKEN = token;

  axiosInstance.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axiosInstance.defaults.headers.common["beco-usertoken"] = AUTH_TOKEN;
  return dispatch => {
    return axiosInstance
      .post(URL.USERDATAENDPOINT, data)
      .then(() => {
        // console.log("rest", res);
        return dispatch({
          type: Types.SUBMIT_USERINFO_SUCCESS
        });
      })
      .catch(err => {
        console.log("err", err);
        // let msg = err?.data?.detail

        let msg = "";
        msg = err.status !== 500 ? err && err.data && err.data.detail : "";
        return dispatch({
          type: Types.SUBMIT_USERINFO_ERROR,
          payload: msg
        });
      });
  };
};
