import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import MapDataProvider from "../../services/mapDataProvider";
import SearchInput from "../searchInput";
import "./style.scss";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multi_direction_visible: false,
      points: [],
      destination: "",
      source: "",
      activeSwap: false
    };
    this.mapDataProvider = MapDataProvider.getInstance();
  }

  handleClick = () => {
    this.props.actions.toggleMultiSelection(true);
  };

  componentDidMount() {}

  closeMultiSearch = () => {
    this.props.actions.toggleMultiSelection(false);
    this.props.actions.setDestinationInputActive(false);
    if (this.props.points.source_point != null)
      this.props.actions.setSourcePoint(null);
    this.props.actions.setSourcePointText("");
  };

  handleDestinationChange = event => {
    if (this.props.points.destination_point != null)
      this.props.actions.setDestinationPoint(null);
    if (this.props.points.dest_search_error)
      this.props.actions.setDestinationSearchError(false);
    this.props.actions.setDestinationPointText(event.target.value);
  };

  handleSourceChange = event => {
    if (this.props.points.source_point != null)
      this.props.actions.setSourcePoint(null);
    if (this.props.points.src_search_error)
      this.props.actions.setSourceSearchError(false);
    this.props.actions.setSourcePointText(event.target.value);
  };

  onDestinationItemClick = item => {
    const { points, floors } = this.props;
    this.props.actions.setCurrentFloor(item.locationId);
    this.mapDataProvider.getMapObject().setCenter();
    this.props.actions.setDestinationPoint(item);
    this.props.actions.setDestinationPointText(item.name);
    this.props.actions.setDestinationSearchError(false);
    if (!points.source_point_text)
      this.mapDataProvider.getMapObject().moveToPoint(item.pointId);

    if (item.locationId !== floors.current_floor)
      this.mapDataProvider.getMapObject().switchFloor(item.locationId);
  };

  onSourceItemClick = item => {
    this.props.actions.setSourcePoint(item);
    this.props.actions.setSourcePointText(item.name);
    this.props.actions.setSourceSearchError(false);
  };

  swapPoints = () => {
    if (
      this.props.points.destination_point_text.trim() != "" ||
      this.props.points.source_point_text.trim() != ""
    ) {
      let source_text = this.props.points.source_point_text;
      let source_pt = this.props.points.source_point;
      let dest_text = this.props.points.destination_point_text;
      let dest_pt = this.props.points.destination_point;
      let src_search_error = this.props.points.src_search_error;
      let dest_search_error = this.props.points.dest_search_error;
      this.props.actions.setDestinationPointText(source_text);
      this.props.actions.setSourcePoint(dest_pt);
      this.props.actions.setSourcePointText(dest_text);
      this.props.actions.setDestinationPoint(source_pt);
      this.props.actions.setDestinationSuggestionVisibility(
        this.props.points.src_suggestion_view
      );
      this.props.actions.setSourceSuggestionVisibility(
        this.props.points.dest_suggestion_view
      );
      this.props.actions.setSourceSearchError(dest_search_error);
      this.props.actions.setDestinationSearchError(src_search_error);
      this.setState(prevState => ({
        activeSwap: !prevState.activeSwap
      }));
    }
  };

  render() {
    if (!this.props.floors.navigationInProgress)
      return (
        <div
          className={
            "beco-search" +
            (this.props.points.multi_point_selection ? " panel" : "")
          }
        >
          {!this.props.points.multi_point_selection ? (
            <div
              className={
                this.props.points.dest_suggestion_view &&
                this.props.points.destination_point_text.trim() != ""
                  ? "beco-search-input-group results-show"
                  : "beco-search-input-group"
              }
            >
              <div className="beco-search-input-group-prepend">
                <span className="icon-beco-search"></span>
              </div>

              <SearchInput
                value={this.props.points.destination_point_text}
                onChange={this.handleDestinationChange}
                inputClassName={"beco-search-input form-control"}
                placeholder={"Where to go ?"}
                name="wheretogo"
                iconClick={this.handleClick}
                onItemClick={this.onDestinationItemClick}
                onFocus={() => {
                  this.props.actions.setSourceSuggestionVisibility(false);
                  this.props.actions.setDestinationSuggestionVisibility(true);
                }}
                onBlur={() =>
                  this.props.actions.setDestinationSuggestionVisibility(false)
                }
                onClose={() => {
                  this.mapDataProvider.getMapObject().setCustomZoom(3);
                  this.mapDataProvider.getMapObject().setCenter();

                  this.props.actions.setDestinationSuggestionVisibility(false);
                  if (this.props.points.destination_point != null)
                    this.props.actions.setDestinationPoint(null);
                  this.props.actions.setDestinationPointText("");
                }}
              />
            </div>
          ) : (
            <div
              className={
                (this.props.points.src_suggestion_view &&
                  this.props.points.source_point_text.trim() != "") ||
                (this.props.points.dest_suggestion_view &&
                  this.props.points.destination_point_text != "")
                  ? "beco-search-route results-show"
                  : "beco-search-route"
              }
            >
              <span
                className="ic-multi-close icon-bc-close"
                onClick={this.closeMultiSearch}
              ></span>
              <div className="beco-search-route-title">
                <h2>Directions</h2>
              </div>
              <div className="beco-search-route-from-to">
                <span className="ic-source icon-bc-current">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
                <span className="ic-destination icon-bc-desti">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>

                <SearchInput
                  value={this.props.points.source_point_text}
                  name="source"
                  onChange={this.handleSourceChange}
                  inputClassName={""}
                  placeholder={"Choose starting point"}
                  iconClick={null}
                  onItemClick={this.onSourceItemClick}
                  onFocus={() => {
                    this.props.actions.setDestinationSuggestionVisibility(
                      false
                    );
                    this.props.actions.setSourceSuggestionVisibility(true);
                  }}
                  onBlur={() =>
                    this.props.actions.setSourceSuggestionVisibility(false)
                  }
                  onClose={() => {
                    this.props.actions.setSourceSuggestionVisibility(false);
                    if (this.props.points.source_point != null)
                      this.props.actions.setSourcePoint(null);
                    this.props.actions.setSourcePointText("");
                    this.props.actions.setSourceSearchError(false);
                  }}
                />

                <SearchInput
                  isFocus={this.props.points.destination_active}
                  name="destination"
                  value={this.props.points.destination_point_text}
                  onChange={this.handleDestinationChange}
                  inputClassName={""}
                  placeholder={"Choose destination"}
                  iconClick={null}
                  onItemClick={this.onDestinationItemClick}
                  onFocus={() => {
                    this.props.actions.setSourceSuggestionVisibility(false);
                    this.props.actions.setDestinationSuggestionVisibility(true);
                  }}
                  onBlur={() =>
                    this.props.actions.setDestinationSuggestionVisibility(false)
                  }
                  onClose={() => {
                    this.mapDataProvider.getMapObject().setCustomZoom(3);
                    this.mapDataProvider.getMapObject().setCenter();

                    this.props.actions.setDestinationSuggestionVisibility(
                      false
                    );
                    if (this.props.points.destination_point != null)
                      this.props.actions.setDestinationPoint(null);
                    this.props.actions.setDestinationPointText("");
                    this.props.actions.setDestinationSearchError(false);
                  }}
                />

                <span
                  className={
                    "icon-bc-swap" + (this.state.activeSwap ? " active" : "")
                  }
                  onClick={this.swapPoints}
                ></span>
              </div>
            </div>
          )}
        </div>
      );
    return null;
  }
}

const mapStateToProps = state => {
  return {
    points: state.points,
    floors: state.floors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

SearchBar.propTypes = {
  actions: PropTypes.object,
  points: PropTypes.object,
  floors: PropTypes.object
};
