import * as Types from '../../constants/actionTypes';


export function setCurrentBuilding(currentBuilding) {
  return (dispatch) => {
    return dispatch({
      type: Types.BUILDING_SELECT_SUCCESS,
      payload: currentBuilding
    });
  };
}

export function saveBuildingList(building_list) {
  return (dispatch) => {
    return dispatch({
      type: Types.SAVE_BUILDING_LIST_SUCCESS,
      payload: building_list
    });
  };
}
