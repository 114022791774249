import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import customToast from "../../helpers/toast";
import "./style.scss";
const customStyles = {
  content: {
    // backgroundColor: '#fff',
    position: "unset",
    top: "unset",
    left: "unset",
    right: "unset",
    bottom: "unset",
    transform: "translate(0, 0)",
    margin: "1.75rem auto"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "999"
  }
};

class CaptureUserInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: true,
      modalSuccess: false,
      name: "",
      phnNumber: "",
      place: "",
      form_errors: {},
      site_identifier: "",
      token: "",
      isLoading: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._addSiteIdentifierAndToken();
  }

  _addSiteIdentifierAndToken = () => {
    const site_identifier = this.props.site_identifier
      ? this.props.site_identifier
      : null;

    const token = this.props.token ? this.props.token : null;
    this.setState({ site_identifier, token });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  saveUserInfo() {
    if (this.isValid()) {
      const { name, phnNumber, place, site_identifier, token } = this.state;
      let data = {
        name: name,
        phone: phnNumber,
        location: place,
        site_identifier
      };
      this.setState({ isLoading: true });
      this.props.actions.postUserInfo(data, token).then(() => {
        const { isUserInfoSubmitted, errorMsg } = this.props.userInfo;

        this.setState({ isLoading: false });

        if (!isUserInfoSubmitted) {
          if (errorMsg !== "") customToast.error(errorMsg);
        } else {
          localStorage.setItem("userInfo", JSON.stringify(data));
          this.setState({ modalSuccess: true }, () => {
            setTimeout(() => {
              this.setState({
                modalIsOpen: false
              });
            }, 4000);
          });
        }
      });
    }
  }

  isValid = () => {
    const { name, phnNumber, place } = this.state;
    let form_errors = {};
    let valid = true;
    let phnNumberRegex = /^[6-9]\d{9}$/;

    if (name.trim().length === 0 || name.trim() === "") {
      valid = false;
      form_errors.name = "Name cannot be empty.";
    } else if (name.trim().length !== 0 || name.trim() !== "") {
      if (name.length < 3) {
        valid = false;
        form_errors.name = "Please enter 3 or more characters";
      }
    }

    if (phnNumber.trim().length === 0 || phnNumber.trim() === "") {
      valid = false;
      form_errors.phnNumber = "Phone number cannot be empty.";
    } else if (phnNumber.trim().length !== 0 || phnNumber.trim() !== "") {
      if (!phnNumberRegex.test(phnNumber.trim())) {
        valid = false;
        form_errors.phnNumber = "Please enter a valid phone number.";
      }
    }
    if (place.trim().length === 0 || place.trim() === "") {
      valid = false;
      form_errors.place = "Place cannot be empty.";
    }

    if (phnNumber.trim().length !== 0 || phnNumber.trim() !== "") {
      if (!phnNumberRegex.test(phnNumber.trim())) {
        valid = false;
        form_errors.phnNumber = "Please enter a valid phone number.";
      }
    }

    this.setState({ form_errors });
    return valid;
  };

  get renderForm() {
    const { form_errors, isLoading } = this.state;
    return (
      <div className="bc-modal-body">
        {/* <span className="icon-info">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
          <span className="path6"></span>
          <span className="path7"></span>
          <span className="path8"></span>
          <span className="path9"></span>
          <span className="path10"></span>
          <span className="path11"></span>
          <span className="path12"></span>
          <span className="path13"></span>
          <span className="path14"></span>
          <span className="path15"></span>
          <span className="path16"></span>
          <span className="path17"></span>
          <span className="path18"></span>
          <span className="path19"></span>
          <span className="path20"></span>
          <span className="path21"></span>
          <span className="path22"></span>
        </span> */}
        <img
          style={{
            maxWidth: '119px',
            marginBottom: '14px'
          }}
          className="brand-logo" src={require('../../images/city-centre-logo.png')} />
        <h6 className="bc-modal-title">Welcome To Nashik City Centre</h6>

        <form>
          <div
            className={form_errors["name"] ? "form-group error" : "form-group"}
          >
            <input
              type="text"
              className="form-control"
              id="InputEmail"
              placeholder="Enter Name"
              name="name"
              onChange={this.onValueChange}
              autoComplete="off"
            />

            <span className="form-error">{form_errors["name"]}</span>
          </div>
          <div
            className={
              form_errors["phnNumber"] ? "form-group error" : "form-group"
            }
          >
            <input
              type="text"
              className="form-control"
              id="InputContact"
              placeholder="Enter Contact No"
              name="phnNumber"
              onChange={this.onValueChange}
              autoComplete="off"
            />

            <span className="form-error">{form_errors["phnNumber"]}</span>
          </div>
          <div
            className={form_errors["place"] ? "form-group error" : "form-group"}
          >
            <input
              type="text"
              className="form-control"
              id="InputPlace"
              placeholder="Enter Your Place"
              onChange={this.onValueChange}
              name="place"
              autoComplete="off"
            />

            <span className="form-error">{form_errors["place"]}</span>
          </div>
        </form>
        <button
          className="btn btn-beco-nv user-info mt-3"
          type="button"
          onClick={() => {
            this.saveUserInfo();
          }}
        >
          {isLoading ? "Loading..." : "Continue"}
        </button>
      </div>
    );
  }

  onValueChange = event => {
    let { form_errors } = this.state;
    form_errors[event.target.name] = "";
    this.setState({
      [event.target.name]: event.target.value,
      form_errors
    });
  };

  get renderFormToast() {
    return (
      <div className="bc-modal-body">
        <div className="sucess-sec">
          <svg
            id="success"
            className="animated"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
          >
            <path
              id="successResult"
              fill="#D8D8D8"
              d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
            />
            <circle
              id="successCircle"
              cx="35"
              cy="35"
              r="24"
              stroke="#979797"
              strokeWidth="2"
              strokeLinecap="round"
              fill="transparent"
            />
            <polyline
              id="successCheck"
              stroke="#979797"
              strokeWidth="2"
              points="23 34 34 43 47 27"
              fill="transparent"
            />
          </svg>
          <h4 className="text-success mb-4">Thank you</h4>
          <p className="mb-4 mx-4">
          {`Follow social distancing norms , maintain a 6' distance from others. Have a safe and happy shopping experience.`}
          </p>
          <button
            className="btn btn-success pd-x-25"
            type="button"
            onClick={() => {
              this.goBackToHome();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  goBackToHome() {
    this.setState({
      modalIsOpen: false
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          ariaHideApp={false}
          // onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Capture User Info"
          className="bc-modal-outer"
        >
          <div className="bc-modal">
            {this.state.modalSuccess ? this.renderFormToast : this.renderForm}
          </div>
        </Modal>
      </div>
    );
  }
}
// export default CaptureUserInfo;

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaptureUserInfo);

CaptureUserInfo.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  }),
  site_identifier: PropTypes.string,
  token: PropTypes.string,
  actions: PropTypes.object,
  userInfo: PropTypes.object
};
