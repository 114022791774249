import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Message = ({ message, status }) => (
  <div className="beco-toast-inner success">
    <span className="beco-toast-inner-icon"></span>
    <div className="beco-toast-inner-body">
      <h5>{status}</h5>
      <p>{message}</p>
    </div>
  </div>
);

const customToast = {
  // success: (message) => {
  //   toast(<Message message={message} status={"Success"} />, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     className: 'beco-toast success',
  //     hideProgressBar: true,
  //     autoClose: 5000
  //   });
  // },
  error: message => {
    toast(<Message message={message} status={"Error"} />, {
      position:
        screen.width < 740
          ? toast.POSITION.BOTTOM_CENTER
          : toast.POSITION.TOP_RIGHT,
      className: "beco-toast error",
      hideProgressBar: true,
      autoClose: 5000
    });
  }
  // warning: (message) => {
  //   toast(<Message message={message} status={"Warning"} />, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     className: 'beco-toast warning',
  //     hideProgressBar: true,
  //     autoClose: 5000
  //   });
  // },
  // info: (message) => {
  //   toast(<Message message={message} status={"Info"} />, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     className: 'beco-toast info',
  //     hideProgressBar: true,
  //     autoClose: 5000
  //   });
  // }
};

Message.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string
};

export default customToast;
