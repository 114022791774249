import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import { getImage } from "../../helpers/images";
import * as Utilities from "../../helpers/utilities";
import MapDataProvider from "../../services/mapDataProvider";
import customToast from "./../../helpers/toast";
import "./style.scss";

// const sendCustomEvent = (category, action, label) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//   });
// };

class NavigationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiFloornavigation: false,
      start_button_visible: true,
      nextStep: null,
      isRouteLoading: false
    };
    this.mapDataProvider = MapDataProvider.getInstance();
    this.routeData = null;
    this.currentStepInfo = { floorRouteIndex: 0, stepIndex: 0 };
    this.nextStepInfo;
  }

  componentDidMount() {}

  drawRoute = () => {
    if (
      this.props.points.source_point == null &&
      !this.props.points.multi_point_selection
    ) {
      this.props.actions.toggleMultiSelection(true);
      return;
    } else if (
      this.props.points.source_point == null &&
      this.props.points.multi_point_selection
    ) {
      this.props.actions.setSourceSearchError(
        this.props.points.source_point == null
      );
      return;
    } else {
      this.props.actions.setSourceSearchError(
        this.props.points.source_point == null
      );
      this.props.actions.setDestinationSearchError(
        this.props.points.destination_point == null
      );
    }
    if (this.props.points.source_point == this.props.points.destination_point) {
      customToast.error("Source and destination can't be the same");
      this.props.actions.setSourceSearchError(true);
      this.props.actions.setDestinationSearchError(true);
      return;
    } else {
      this.props.actions.setSourceSearchError(false);
      this.props.actions.setDestinationSearchError(false);
    }
    this.setState({
      isRouteLoading: true
    });
    if (
      this.props.points.source_point != null &&
      this.props.points.destination_point != null &&
      this.props.points.source_point != this.props.points.destination_point
    ) {
      this.mapDataProvider
        .getMapObject()
        .getRoute(
          this.props.points.source_point.nodeId,
          this.props.points.destination_point.nodeId
        );
      // sendCustomEvent('Routes', 'Route Start', `${this.props.points.source_point.name} - ${this.props.points.destination_point.name}`);
      this.mapDataProvider
        .getMapObject()
        .setOnRouteFinishedListner(this.routeCallback);
    }
  };

  /**
   * receieves a route, responsible for drawing route
   * @param {Object} route route object
   */
  routeCallback = route => {
    if (route.status && route.status == "FAILED") {
      customToast.error("No route found.");
      this.setState({
        isRouteLoading: false
      });
      return;
    }
    this.routeData = route;
    let floorRoute = this.routeData.floorRouts[
      this.currentStepInfo.floorRouteIndex
    ];
    let step = floorRoute.steps[this.currentStepInfo.stepIndex];
    let nextStep = { floorRoute, step };
    this.switchFloorWhileNavigation(nextStep.floorRoute.floor_id);
    this.modifyURLSearchParams(0);
    this.modifyURLSearchParams(1);
    this.setState({
      nextStep,
      start_button_visible: false,
      isRouteLoading: false
    });
    this.props.actions.previewRouteStart(true);

    if (this.routeData.floorRouts.length > 1)
      this.setState({
        multiFloornavigation: true
      });
    else
      this.setState({
        multiFloornavigation: false
      });
    this.mapDataProvider.setNavigationRoute(this.routeData);
    this.props.actions.saveActiveFloorStatus(
      this.routeData.floorRouts[0].floor_id
    );
    this.props.actions.setNavigationProgress(true);
    // this.mapDataProvider.getMapObject().drawLiveRoute(floorRoute, step);
    this.mapDataProvider
      .getMapObject()
      .drawRoute(floorRoute.getCompletePathCoordinates());
  };

  /**
   * Route preview update function
   * @param {String} action prev or next
   */
  previewRoute(action) {
    if (action == "prev") {
      this.nextStepInfo = this.props.points.current_route_data.getStepBefore(
        this.currentStepInfo.floorRouteIndex,
        this.currentStepInfo.stepIndex
      );
    } else if (action == "next") {
      this.nextStepInfo = this.props.points.current_route_data.getStepAfter(
        this.currentStepInfo.floorRouteIndex,
        this.currentStepInfo.stepIndex
      );
    }
    if (this.nextStepInfo) {
      this.currentStepInfo.floorRouteIndex = this.nextStepInfo.floorRoute.floorRouteIndex;
      this.currentStepInfo.stepIndex = this.nextStepInfo.step.stepIndex;
      this.setState({
        nextStep: this.nextStepInfo
      });
      if (
        this.props.floors.activeFloorButton !=
        this.nextStepInfo.floorRoute.floor_id
      )
        this.props.actions.saveActiveFloorStatus(
          this.nextStepInfo.floorRoute.floor_id
        );
      this.switchFloorWhileNavigation(this.nextStepInfo.floorRoute.floor_id);
      this.mapDataProvider
        .getMapObject()
        .drawLiveRoute(this.nextStepInfo.floorRoute, this.nextStepInfo.step);
    }
  }

  /**
   * Switch to the provided floor
   * @param {Number} nextFloorId floor id
   */
  switchFloorWhileNavigation(nextFloorId) {
    if (this.props.floors.current_floor != nextFloorId) {
      // check if the user is not in the same floor
      this.props.actions.setCurrentFloor(nextFloorId); // set current floor to new floor id
      this.mapDataProvider.getMapObject().switchFloor(nextFloorId); // switch floor to the new floor
      // this.mapDataProvider.getMapObject().setCenter(); //center align the map
    }
  }

  /**
   * Draw floor wise route.
   * Floor routepath destructured to it's corresponding x and y coordinates
   * @param {Object} route floor wise route
   */
  drawFloorWiseRoute(route) {
    this.mapDataProvider.getMapObject().setCenter();
    this.mapDataProvider.getMapObject().setCustomZoom(2);
    this.props.actions.saveActiveFloorStatus(route.floor_id);
    this.mapDataProvider.getMapObject().switchFloor(route.floor_id);
    this.props.actions.setCurrentFloor(route.floor_id);
    this.mapDataProvider
      .getMapObject()
      .drawRoute(route.getCompletePathCoordinates());
  }

  /**
   * Get information about a floor
   * @param {Number} locationId id of the floor
   */
  getFloorInfo = locationId => {
    let floor = this.props.floors.floors_list.find(
      floor => locationId == floor.id
    );
    return floor;
  };

  /**
   * clear selected points
   */
  cancelPointSelection = () => {
    this.modifyURLSearchParams(0);
    if (!this.state.start_button_visible) {
      if (this.state.nextStep.step.nextNode === undefined) {
        this._isStartBtnVisibleOnCancel();
        this._startBtnHiddenOnCancelFunc();
      } else this._isStartBtnVisibleOnCancel();
    } else {
      this._startBtnHiddenOnCancelFunc();
    }
  };

  _isStartBtnVisibleOnCancel = () => {
    //comment or remove below line if you choose to clear points and search when end route preview button pressed
    this.routeData = null;
    this.setState({
      multiFloornavigation: false,
      start_button_visible: true
    });
    this.props.actions.previewRouteStart(true);
    this.props.actions.setNavigationProgress(false);
    this.mapDataProvider.getMapObject().cancelNavigation();
    this.mapDataProvider.getMapObject().setCustomZoom(3);
    this.mapDataProvider.getMapObject().rotate(0, null);

    this.mapDataProvider
      .getMapObject()
      .switchFloor(this.mapDataProvider.getInitialFloor());
    this.props.actions.setCurrentFloor(this.mapDataProvider.getInitialFloor());
    this.props.actions.saveActiveFloorStatus("");
  };

  _startBtnHiddenOnCancelFunc = () => {
    this.mapDataProvider.getMapObject().setCustomZoom(3);
    this.mapDataProvider.getMapObject().setCenter();

    this.props.actions.setDestinationPoint(null);
    this.props.actions.setDestinationPointText("");
    this.props.actions.setSourcePoint(null);
    this.props.actions.setSourcePointText("");
    this.props.actions.toggleMultiSelection(false);
    this.props.actions.previewRouteStart(false);
  };

  /**
   * Add or Remove src and dest param from url
   * @param {Number} value 0 or 1 0 to remove the params, 1 to add the params
   */
  modifyURLSearchParams(value) {
    //modify the current url search param
    let removed_search;
    let added_search;
    let search = this.props.location.search;
    switch (value) {
      case 0:
        removed_search = Utilities.removeParam(
          "dest",
          Utilities.removeParam("src", search)
        ); //remove src and dest url search param
        this.props.history.replace({
          pathname: "/",
          search: removed_search
        });
        break;
      case 1:
        added_search =
          search +
          "&src=" +
          this.props.points.source_point.uuid +
          "&dest=" +
          this.props.points.destination_point.uuid; //add src and dest url search param
        this.props.history.replace({
          pathname: "/",
          search: added_search
        });
        break;
    }
  }

  /**
   * render multiple floor info, if the navigation route has multiple
   * floors involved
   * @param {String} mode preview or action, if preview, no click action
   */
  renderMultiFloorInfo = mode => {
    if (this.mapDataProvider.getNavigationRoute() != null) {
      const { floorRouts } = this.mapDataProvider.getNavigationRoute();
      return (
        <div className="multi-swipe">
          {floorRouts.map(floor => (
              <div className={mode == "preview" ? "preview" : ""}>
                <label
                  className={
                    this.props.floors.activeFloorButton == floor.floor_id
                      ? "btn btn-beco-mul active"
                      : "btn btn-beco-mul"
                  }
                  key={floor.floor_id}
                  style={{ "fontSize": "18px", "lineHeight": "16px" }}
                >
                  <input
                    type="radio"
                    name="first"
                    id="option1"
                    autoComplete="off"
                    onClick={() =>
                      mode == "preview" ? null : this.drawFloorWiseRoute(floor)
                    }
                  />
                  {this.getFloorInfo(floor.floor_id) ? (
                    <>
                      {this.getFloorInfo(floor.floor_id).label}
                      { mode == "navigation" && <span style={{ "textWrap": "nowrap", "fontSize": "10px", "display": "block", "textTransform": "uppercase", "fontWeight": "500", "letterSpacing": "0.5px", "lineHeight": "11px", "marginTop": "2px" }}>{this.getFloorInfo(floor.floor_id).description}</span>}
                    </>) : ""}
                </label>
              </div>
          ))}
        </div>
      );
    }
    return null;
  };

  /**
   * End route preview
   * Hide route preview window
   */
  endRoutePreview = () => {
    this.drawFloorWiseRoute(
      this.props.points.current_route_data.floorRouts[
      this.currentStepInfo.floorRouteIndex
      ]
    );
    this.props.actions.setCurrentRoute(null);
    this.currentStepInfo = { floorRouteIndex: 0, stepIndex: 0 };

    // reset the nextStep to initial step
    let floorRoute = this.routeData.floorRouts[
      this.currentStepInfo.floorRouteIndex
    ];
    let step = floorRoute.steps[this.currentStepInfo.stepIndex];
    let nextStep = { floorRoute, step };
    this.setState({
      nextStep
    });
    this.mapDataProvider.getMapObject().setCustomZoom(3);
    // this.mapDataProvider.getMapObject().setRotateAngle(0);
    this.mapDataProvider.getMapObject().rotate(0, null);

    // uncomment below lines to clear route and point selection when end route preview button pressed
    // this.routeData = null;
    this.cancelPointSelection();
    // this.props.actions.setNavigationProgress(false);
  };

  /**
   * Show preview
   * Show route preview window
   */

  showPreview = async () => {
    if (this.routeData) {
      await this.props.actions.setCurrentRoute(this.routeData);
      if (
        this.props.floors.activeFloorButton !=
        this.props.points.current_route_data.floorRouts[0].floor_id
      )
        this.props.actions.saveActiveFloorStatus(
          this.props.points.current_route_data.floorRouts[0].floor_id
        );
      this.switchFloorWhileNavigation(
        this.props.points.current_route_data.floorRouts[0].floor_id
      );

      let floorRoute = this.routeData.floorRouts[
        this.currentStepInfo.floorRouteIndex
      ];
      let step = floorRoute.steps[this.currentStepInfo.stepIndex];
      let nextStep = { floorRoute, step };
      // this.mapDataProvider.getMapObject().drawLiveRoute(floorRoute, step);
      this.mapDataProvider
        .getMapObject()
        .drawLiveRoute(nextStep.floorRoute, nextStep.step);
    }
  };

  render() {
    return (
      <div>
        {this.props.points.current_route_data && (
          <div className="prev-sec">
            <div className="preview-sec">
              <div className="inner-sec">
                <div className="inner-sec-body">
                  <div className="direction-img">
                    <img
                      src={require("../../images/" +
                        getImage(this.state.nextStep.step.stepImageType) +
                        ".svg")}
                    />
                  </div>
                  <div className="direction-label">
                    <h4>
                      {this.state.nextStep != null
                        ? this.state.nextStep.step.stepMessage
                        : ""}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="bt-cmp" className="beco-bt-cmp">
          {this.props.points.current_route_data && (
            <>
              <div className="n-p-btn">
                {this.state.nextStep && (
                  <>
                    <button
                      className="pre-btn left"
                      type="button"
                      style={{
                        opacity:
                          this.state.nextStep.step.previousNode ||
                            this.currentStepInfo.floorRouteIndex > 0
                            ? 1
                            : 0.5
                      }}
                      onClick={() => this.previewRoute("prev")}
                    >
                      <span className="preview-ic icon-back"></span>
                    </button>
                    <button
                      className="pre-btn right"
                      type="button"
                      style={{
                        opacity:
                          this.state.nextStep.step.nextNode ||
                            this.currentStepInfo.floorRouteIndex <
                            this.routeData.floorRouts.length - 1
                            ? 1
                            : 0.5
                      }}
                      onClick={() => this.previewRoute("next")}
                    >
                      <span className="preview-ic icon-next"></span>
                    </button>
                  </>
                )}
              </div>
              <div className="preview-footer bt-cmp-navigation">
                <div className="bt-cmp-navigation-text">
                  <div>
                    <h2>
                      {this.props.points.destination_point
                        ? this.props.points.destination_point.name
                        : ""}
                    </h2>
                    <p>
                      {
                        this.getFloorInfo(
                          this.props.points.destination_point.locationId
                        ).description
                      }
                    </p>
                  </div>
                </div>
                {this.state.multiFloornavigation && (
                  <div className="bt-cmp-navigation-multi is-no">
                    <p>Your route covers multiple levels.</p>
                    <div className="bt-cmp-navigation-multi-group">
                      {this.renderMultiFloorInfo("preview")}
                    </div>
                  </div>
                )}
                <div className="bt-cmp-navigation-btn">
                  <button
                    className="btn end-btn"
                    type="button"
                    onClick={this.endRoutePreview}
                  >
                    End
                  </button>
                </div>
              </div>
            </>
          )}
          {this.props.points.destination_point &&
            !this.props.points.current_route_data && (
              <div className="bt-cmp-navigation">
                <div className="bt-cmp-navigation-text">
                  <h2>{this.props.points.destination_point.name}</h2>
                  <h5>
                    {
                      this.getFloorInfo(
                        this.props.points.destination_point.locationId
                      ).description
                    }
                  </h5>
                </div>
                {this.state.multiFloornavigation && (
                  <div className="bt-cmp-navigation-multi">
                    <p>Your route covers multiple levels.</p>
                    <div className="bt-cmp-navigation-multi-group">
                      {this.renderMultiFloorInfo("navigation")}
                    </div>
                  </div>
                )}
                {this.props.points.destination_point != null && (
                  <div className="bt-cmp-navigation-btn">
                    <button
                      className="btn btn-beco-cn"
                      type="button"
                      // onClick={this.cancelPointSelection}
                      onClick={() => {
                        this.cancelPointSelection();
                      }}
                    >
                      Cancel
                    </button>
                    {!this.state.start_button_visible && (
                      <button
                        className="btn btn-beco-nv"
                        type="button"
                        onClick={this.showPreview}
                      >
                        <span className="icon-bc-compass"></span>
                        Start
                      </button>
                    )}
                    {this.state.start_button_visible && (
                      <button
                        className="btn btn-beco-nv"
                        type="button"
                        onClick={
                          this.state.isRouteLoading ? null : this.drawRoute
                          // this.showPreview
                        }
                      >
                        {!this.state.isRouteLoading && (
                          <span className="icon-bc-compass"></span>
                        )}
                        {!this.state.isRouteLoading
                          ? this.props.points.source_point != null &&
                            this.props.points.destination_point != null
                            ? "Preview"
                            : "Navigate"
                          : "Loading..."}
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    points: state.points,
    floors: state.floors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationCard)
);

NavigationCard.propTypes = {
  actions: PropTypes.object,
  points: PropTypes.object,
  floors: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};
