import * as Types from '../../constants/actionTypes';

/**
 * reducer which holds the current selected floor.
 */

const initialState = {
  source_point_text: '',
  destination_point_text: '',
  source_point: null,
  destination_point: null,
  points_list: [],
  multi_point_selection: false,
  preview_start: false,
  destination_active: false,

  src_suggestion_view: false,
  dest_suggestion_view: false,

  src_search_error: false,
  dest_search_error: false,

  current_route_data: null
};

export default function points(state = initialState, action) {
  switch (action.type) {
    case Types.SET_MULTI_SELECT:
      return { ...state, multi_point_selection: action.payload };
    case Types.PREVIEW_START:
      return { ...state, preview_start: action.payload };
    case Types.SET_SOURCE_POINT:
      return { ...state, source_point: null };
    case Types.SET_SOURCE_POINT_SEARCH:
      return { ...state, source_point_text: '' };
    case Types.SET_SOURCE_POINT_SUCCESS:
      return { ...state, source_point: action.payload };
    case Types.SET_SOURCE_POINT_SEARCH_SUCCESS:
      return { ...state, source_point_text: action.payload };

    case Types.SET_SOURCE_SEARCH_ERROR:
      return { ...state, src_search_error: action.payload };

    case Types.SET_DESTINATION_POINT:
      return { ...state, destination_point: null };
    case Types.SET_DESTINATION_POINT_SEARCH:
      return { ...state, destination_point_text: '' };
    case Types.SET_DESTINATION_POINT_SUCCESS:
      return { ...state, destination_point: action.payload };
    case Types.SET_DESTINATION_POINT_SEARCH_SUCCESS:
      return { ...state, destination_point_text: action.payload };
    case Types.SAVE_POINTS_LIST_SUCCESS:
      return { ...state, points_list: action.payload };

    case Types.SET_DESTINATION_SEARCH_ERROR:
      return { ...state, dest_search_error: action.payload };


    case Types.SET_SRC_SUGGESTION_VIEW:
      return { ...state, src_suggestion_view: action.payload };
    case Types.SET_DEST_SUGGESTION_VIEW:
      return { ...state, dest_suggestion_view: action.payload };

    case Types.SET_CURRENT_ROUTE_START:
      return { ...state, current_route_data: null };
    case Types.SET_CURRENT_ROUTE:
      return { ...state, current_route_data: action.payload };
    case Types.SET_DESTINATION_POINT_INPUT_ACTIVE:
      return {...state, destination_active: action.payload};

    default:
      return state;
  }
}