const markers = {
  "default": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/common.svg",
    "imageSmall": "small/common.svg"
  },
  "cafe": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/cafe.svg",
    "imageSmall": "small/cafe.svg"
  },
  "workstation": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/workstation.svg",
    "imageSmall": "small/workstation.svg"
  },
  "meeting": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/meeting.svg",
    "imageSmall": "small/meeting.svg"
  },
  "lab": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/lab.svg",
    "imageSmall": "small/lab.svg"
  },
  "Laboratory": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/lab.svg",
    "imageSmall": "small/lab.svg"
  },
  "entrance": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/entrance.svg",
    "imageSmall": "small/entrance.svg"
  },
  "lift": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/lift.svg",
    "imageSmall": "small/lift.svg"
  },
  "reception": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/reception.svg",
    "imageSmall": "small/reception.svg"
  },
  "room": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/room.svg",
    "imageSmall": "small/room.svg"
  },
  "ward": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/ward.svg",
    "imageSmall": "small/ward.svg"
  },
  "staircase": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/staircase.svg",
    "imageSmall": "small/staircase.svg"
  },
  "exit": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/exit.svg",
    "imageSmall": "small/exit.svg"
  },
  "restrooms": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/restrooms.svg",
    "imageSmall": "small/restrooms.svg"
  },
  "atm": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/atm.svg",
    "imageSmall": "small/atm.svg"
  },
  "fire-exit": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/emergencyexit.svg",
    "imageSmall": "small/emergencyexit.svg"
  },
  "baby-care-room": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/babycareroom.svg",
    "imageSmall": "small/babycareroom.svg"
  },
  "baggage-center": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/baggagecenter.svg",
    "imageSmall": "small/baggagecenter.svg"
  },
  "drinking-water": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/drinkingwater.svg",
    "imageSmall": "small/drinkingwater.svg"
  },
  "escalator": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/escalator.svg",
    "imageSmall": "small/escalator.svg"
  },
  "lost-and-found": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/lostfound.svg",
    "imageSmall": "small/lostfound.svg"
  },
  "pram-service": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/pramservice.svg",
    "imageSmall": "small/pramservice.svg"
  },
  "restroom-differently-abled": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/Restroomfordifferentlyabled.svg",
    "imageSmall": "small/Restroomfordifferentlyabled.svg"
  },
  "customer-desk": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/customerdesk.svg",
    "imageSmall": "small/customerdesk.svg"
  },
  "wheelchair": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/wheelchair.svg",
    "imageSmall": "small/wheelchair.svg"
  },
  "community-center": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/communitycenter.svg",
    "imageSmall": "small/communitycenter.svg"
  },
  "auto-stand": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/autostand.svg",
    "imageSmall": "small/autostand.svg"
  },
  "first-aid": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/firstaid.svg",
    "imageSmall": "small/firstaid.svg"
  },
  "hospital": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/hospital.svg",
    "imageSmall": "small/hospital.svg"
  },
  "tailor": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/tailor.svg",
    "imageSmall": "small/tailor.svg"
  },
  "key-maker": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/keymaker.svg",
    "imageSmall": "small/keymaker.svg"
  },
  "accessories": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/accessories.svg",
    "imageSmall": "small/accessories.svg"
  },
  "childrens-fashion": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/childrensfashion.svg",
    "imageSmall": "small/childrensfashion.svg"
  },
  "cosmetics": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/cosmetics.svg",
    "imageSmall": "small/cosmetics.svg"
  },
  "fashion": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/fashion.svg",
    "imageSmall": "small/fashion.svg"
  },
  "footwear": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/footwear.svg",
    "imageSmall": "small/footwear.svg"
  },
  "mens-fashion": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/mensfashion.svg",
    "imageSmall": "small/mensfashion.svg"
  },
  "women-fashion": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/womenfashion.svg",
    "imageSmall": "small/womenfashion.svg"
  },
  "passage": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/passage.svg",
    "imageSmall": "small/passage.svg"
  },
  "watches-and-sunglasses": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/watchessunglasses.svg",
    "imageSmall": "small/watchessunglasses.svg"
  },
  "salon": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/salon.svg",
    "imageSmall": "small/salon.svg"
  },
  "multiplex": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/multiplex.svg",
    "imageSmall": "small/multiplex.svg"
  },
  "kids": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/kids.svg",
    "imageSmall": "small/kids.svg"
  },
  "jewellery": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/jewellery.svg",
    "imageSmall": "small/jewellery.svg"
  },
  "innerwear-and-lingerie": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/innerwearlingerie.svg",
    "imageSmall": "small/innerwearlingerie.svg"
  },
  "home-&-lifestyle": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/homelifestyle.svg",
    "imageSmall": "small/homelifestyle.svg"
  },
  "gifts-&-books": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/giftsbooks.svg",
    "imageSmall": "small/giftsbooks.svg"
  },
  "gamezone": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/gamezone.svg",
    "imageSmall": "small/gamezone.svg"
  },
  "food": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/food.svg",
    "imageSmall": "small/food.svg"
  },
  "canteen": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/canteen.svg",
    "imageSmall": "small/canteen.svg"
  },
  "eyewear": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/eyewear.svg",
    "imageSmall": "small/eyewear.svg"
  },
  "electronics": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/electronics.svg",
    "imageSmall": "small/electronics.svg"
  },
  "department-store": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/departmentstore.svg",
    "imageSmall": "small/departmentstore.svg"
  },
  "beauty-and-wellness": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/beautywellness.svg",
    "imageSmall": "small/beautywellness.svg"
  },
  "bags-and-footwear": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/bagsfootwear.svg",
    "imageSmall": "small/bagsfootwear.svg"
  },
  "kiosk": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/kiosk.svg",
    "imageSmall": "small/kiosk.svg"
  },
  "blood-bank": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/bloodbank.svg",
    "imageSmall": "small/bloodbank.svg"
  },
  "cardiology": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/cardiology.svg",
    "imageSmall": "small/cardiology.svg"
  },
  "patient-service": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/patientservice.svg",
    "imageSmall": "small/patientservice.svg"
  },
  "care-unit": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/careunit.svg",
    "imageSmall": "small/careunit.svg"
  },
  "security-office": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/securityoffice.svg",
    "imageSmall": "small/securityoffice.svg"
  },
  "lounge": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/lounge.svg",
    "imageSmall": "small/lounge.svg"
  },
  "prayer-room": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/prayerroom.svg",
    "imageSmall": "small/prayerroom.svg"
  },
  "waiting-area": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/waitingarea.svg",
    "imageSmall": "small/waitingarea.svg"
  },
  "psychiatry": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/psychiatry.svg",
    "imageSmall": "small/psychiatry.svg"
  },
  "radiology": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/radiology.svg",
    "imageSmall": "small/radiology.svg"
  },
  "urology": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/urology.svg",
    "imageSmall": "small/urology.svg"
  },
  "pharmacy": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/pharmacy.svg",
    "imageSmall": "small/pharmacy.svg"
  },
  "out-patient-clinic": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/op.svg",
    "imageSmall": "small/op.svg"
  },
  "billing-counter": {
    "basePath": "public/images/kiosk/",
    "imageLarge": "big/billing.svg",
    "imageSmall": "small/billing.svg"
  },

  
};

export default markers;