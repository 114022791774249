import React from 'react';
import FloorSwitcher from '../floorSwitcher';
import ZoomButton from '../zoomButton';
import './style.scss';

export default class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      isDesktop: false
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.updatePredicateThrottled = this.throttle(this.updatePredicate, 200);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicateThrottled);

    const btCmpElement = document.getElementById('bt-cmp');

    if (btCmpElement) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          this.setState({
            height: entry.target.clientHeight + 15,
          });
        }
      });
      resizeObserver.observe(btCmpElement);
      this.setState({
        height: btCmpElement.clientHeight + 15,
      });
    } 
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicateThrottled);

    const btCmpElement = document.getElementById('bt-cmp');
    if (btCmpElement) {
      resizeObserver.unobserve(btCmpElement);
    }
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 575.98 });
  }

  throttle(func, limit) {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  render() {
    const { height, isDesktop } = this.state;
    return (
      <div className="beco-footer"
        style={{
          bottom: isDesktop ? 10 : height,
        }}
      >
        <FloorSwitcher />
        <ZoomButton />
      </div >
    );
  }
}
