import * as Types from '../../constants/actionTypes';

/**
 * redux action to update the current selected floor
 */

export function setCurrentFloor(currentFloor) {
  return (dispatch) => {
    return dispatch({
      type: Types.FLOOR_SELECT_SUCCESS,
      payload: currentFloor
    });
  };
}

export function saveFloorList(floor_list) {
  return (dispatch) => {
    return dispatch({
      type: Types.SAVE_FLOOR_LIST_SUCCESS,
      payload: floor_list
    });
  };
}

export function saveFloorsList(floors_list) {
  return (dispatch) => {
    return dispatch({
      type: Types.SAVE_FLOORS_LIST_SUCCESS,
      payload: floors_list
    });
  };
}

export function saveActiveFloorStatus(active_floor) {
  return (dispatch) => {
    return dispatch({
      type: Types.SAVE_ACTIVE_FLOOR,
      payload: active_floor
    });
  };
}

export function setNavigationProgress(value) {
  return (dispatch) => {
    return dispatch({
      type: Types.SET_NAVIGATION_STATE,
      payload: value
    });
  };
}