export const SET_MULTI_SELECT = "SET_MULTI_SELECT";
export const PREVIEW_START = "PREVIEW_START";

export const SET_SRC_SUGGESTION_VIEW = "SET_SRC_SUGGESTION_VIEW";
export const SET_DEST_SUGGESTION_VIEW = "SET_DEST_SUGGESTION_VIEW";

export const SET_SOURCE_SEARCH_ERROR = "SET_SOURCE_SEARCH_ERROR";
export const SET_DESTINATION_SEARCH_ERROR = "SET_DESTINATION_SEARCH_ERROR";

export const FLOOR_SELECT_SUCCESS = "FLOOR_SELECT_SUCCESS";
export const SAVE_FLOOR_LIST_SUCCESS = "SAVE_FLOOR_LIST_SUCCESS";
export const SAVE_FLOORS_LIST_SUCCESS = "SAVE_FLOORS_LIST_SUCCESS";
export const SAVE_ACTIVE_FLOOR = "SAVE_ACTIVE_FLOOR";
export const SET_NAVIGATION_STATE = "SET_NAVIGATION_STATE";

export const SET_SOURCE_POINT = "SET_SOURCE_POINT";
export const SET_SOURCE_POINT_SEARCH = "SET_SOURCE_POINT_SEARCH";
export const SET_SOURCE_POINT_SEARCH_SUCCESS =
  "SET_SOURCE_POINT_SEARCH_SUCCESS";
export const SET_SOURCE_POINT_SUCCESS = "SET_SOURCE_POINT_SUCCESS";
export const SET_DESTINATION_POINT = "SET_DESTINATION_POINT";
export const SET_DESTINATION_POINT_SEARCH = "SET_DESTINATION_POINT_SEARCH";
export const SET_DESTINATION_POINT_SEARCH_SUCCESS =
  "SET_DESTINATION_POINT_SEARCH_SUCCESS";
export const SET_DESTINATION_POINT_SUCCESS = "SET_DESTINATION_POINT_SUCCESS";
export const SET_DESTINATION_POINT_INPUT_ACTIVE = "SET_DESTINATION_POINT_INPUT_ACTIVE";
export const SAVE_POINTS_LIST_SUCCESS = "SAVE_POINTS_LIST_SUCCESS";
export const SET_CURRENT_ROUTE_START = "SET_CURRENT_ROUTE_START";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";

export const SUBMIT_USERINFO_SUCCESS = "SUBMIT_USERINFO_SUCCESS";
export const SUBMIT_USERINFO_ERROR = "const SUBMIT_USERINFO_ERROR";

export const BUILDING_SELECT_SUCCESS = "BUILDING_SELECT_SUCCESS";
export const SAVE_BUILDING_LIST_SUCCESS = "SAVE_BUILDING_LIST_SUCCESS";
export const SAVE_ACTIVE_BUILDING = "SAVE_ACTIVE_BUILDING";