/**
 * reducer which holds the current selected floor.
 */
import * as Types from "../../constants/actionTypes";
const initialState = {
  isUserInfoSubmitted: true,
  errorMsg: ""
};

export default function userInfo(state = initialState, action) {
  switch (action.type) {
    case Types.SUBMIT_USERINFO_SUCCESS:
      return {
        ...state,
        isUserInfoSubmitted: true,
        errorMsg: ""
      };
    case Types.SUBMIT_USERINFO_ERROR:
      return {
        ...state,
        isUserInfoSubmitted: false,
        errorMsg: action.payload
      };

    default:
      return state;
  }
}
