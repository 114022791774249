import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions/index";
import MapDataProvider from "../../services/mapDataProvider";

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: [],
      floors: [],
      value: this.props.value
    };
    this.inputRef = React.createRef();
    this.mapDataProvider = MapDataProvider.getInstance();
  }

  componentDidMount() {
    this.getPoints();
    if (this.props.isFocus && this.inputRef.current?.name === 'destination') {
      this.inputRef.current.focus();
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      return {
        value: props.value
      };
    }
    return null;
  }

  async getPoints() {
    let points = await this.mapDataProvider.getMapObject().getPoints();
    let floors = this.props.floors;
    if (points) {
      this.setState({
        points
      });
      this.props.actions.savePointsList(points);
    }
    if (floors) {
      this.setState({
        floors
      });
    }
  }

  filterList() {
    let list = [];
    if (this.state.value.trim() != "") {
      this.state.points.map(item => {
        if ( item.searchable && 
          item.name
            .toLowerCase()
            .includes(this.state.value.trim().toLowerCase())
        )
          list.push(item);
      });
      return list;
    } else {
      return this.state.points;
    }
  }

  renderPoints = () => {
    const { floors } = this.props;
    if (this.filterList().length > 0)
      return this.filterList().map(item => {
        const getFloor = floors.floors_list.find(floor => floor.id === item.locationId);
        return (
          <li key={item.nodeId}>
            <div className="list-item" onClick={() => this.listClick(item)}>
              <span className="ic-list-item icon-bc-location"></span>
              <div className="txt-list-item">
                <span>{item.name}</span>
                <span style={{
                      fontSize:'12px',
                      opacity: '0.6',
                      lineHeight: '1'
                }}>{getFloor.description}</span>
              </div>
            </div>
          </li>
        );
      });
    else
      return (
        <li className="not-found">
          <div className="list-item">
            <span className="ic-not-found icon-bc-location-not"></span>
            <span className="txt-not-found">nothing found</span>
          </div>
        </li>
      );
  };

  listClick = item => {
    this.props.onBlur();
    this.props.onItemClick(item);
  };

  render() {
    let {
      onChange,
      onFocus,
      name,
      inputClassName,
      listClassName,
      placeholder,
      iconClick,
      onClose
    } = this.props;
    let { value } = this.state;
    if (placeholder === "Where to go ?")
      return (
        <>
          <input
            type="text"
            onFocus={onFocus}
            className={inputClassName}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          <div className="beco-search-input-group-append">
            {value.trim() != "" ? (
              <button
                type="button"
                className="btn ic-close-seacrch"
                onClick={onClose}
              >
                <span className="icon-bc-close"></span>
              </button>
            ) : (
              <button
                type="button"
                className="btn navigation-btn"
                onClick={iconClick}
              >
                <span className="icon-bc-navigation"></span>
              </button>
            )}
          </div>
          {this.props.points.dest_suggestion_view && value.trim() != "" && (
            <div className="beco-search-results">
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={164}>
                <ul className="search-list-sec">{this.renderPoints()}</ul>
              </Scrollbars>
            </div>
          )}
        </>
      );
    else if (placeholder === "Choose starting point")
      return (
        <>
          <div className="beco-search-container">
            <input
              autoFocus={value.length > 0 ? false : true}
              style={
                this.props.points.src_search_error
                  ? { borderColor: "#e74c3c" }
                  : {}
              }
              type="text"
              onFocus={onFocus}
              className={inputClassName}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />
            {value.trim() != "" && (
              <button className="btn ic-close" onClick={onClose}>
                <span className="icon-bc-close"></span>
              </button>
            )}
          </div>
          {this.props.points.src_suggestion_view && value.trim() != "" && (
            <div className={(listClassName, "search-list-form-to")}>
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={164}>
                <ul className={"search-list-sec"}>{this.renderPoints()}</ul>
              </Scrollbars>
            </div>
          )}
        </>
      );
    else
      return (
        <>
          <div className="beco-search-container">
            <input
            ref={this.inputRef}
            name={name}
              style={
                this.props.points.dest_search_error
                  ? { borderColor: "#e74c3c" }
                  : {}
              }
              type="text"
              onFocus={onFocus}
              className={inputClassName}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />
            {value.trim() != "" && (
              <button className="btn ic-close" onClick={onClose}>
                <span className="icon-bc-close"></span>
              </button>
            )}
          </div>
          {this.props.points.dest_suggestion_view && value.trim() != "" && (
            <div className={(listClassName, "search-list-form-to")}>
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={164}>
                <ul className={(listClassName, "search-list-sec")}>
                  {this.renderPoints()}
                </ul>
              </Scrollbars>
            </div>
          )}
        </>
      );
  }
}

const mapStateToProps = state => {
  return {
    points: state.points,
    floors: state.floors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);

SearchInput.propTypes = {
  actions: PropTypes.object,
  points: PropTypes.object,
  floors: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputClassName: PropTypes.string,
  listClassName: PropTypes.string,
  placeholder: PropTypes.string,
  iconClick: PropTypes.func,
  onItemClick: PropTypes.func,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};
