import React from 'react';
import '../style/index.scss';
import PropTypes from 'prop-types';
import * as Bootstrap from 'react-bootstrap';

export default class NotFound extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="beco-404">
        <Bootstrap.Container>
          <Bootstrap.Row>
            <Bootstrap.Col md={12}>
              <div className="beco-404-img"></div>
              <h3 className="beco-404-title">Oops!</h3>
              <p className="beco-404-message">Please check if you have entered the correct url.</p>
            </Bootstrap.Col>
          </Bootstrap.Row>
        </Bootstrap.Container>
      </div>
    );
  }
}

NotFound.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.object
  }),
};