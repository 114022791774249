import React from 'react';
import MapDataProvider from '../../services/mapDataProvider';
import './style.scss';

export default class ZoomButton extends React.Component {

  constructor(props) {
    super(props);
    this.mapDataProvider = MapDataProvider.getInstance();
  }

  zoomIn = () => {
    this.mapDataProvider.getMapObject().zoomIn();
  }

  zoomOut = () => {
    this.mapDataProvider.getMapObject().zoomOut();
  }

  render() {
    return (
      <div className="beco-zoom">
        <div id="plus-zoom-in" onClick={this.zoomIn}>
          <span className="icon-plus"></span>
        </div>
        <div id="minus-zoom-out" onClick={this.zoomOut}>
          <span className="icon-minus"></span>
        </div>
      </div >
    );
  }
}
